import(/* webpackMode: "eager" */ "/app/frontend/packages/ui/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSideInstrumentation"] */ "/app/frontend/site/src/instrumentation/client-instrumentation.ts");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_5nrp6tmwbahbf3fygbuf6deyri/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_5nrp6tmwbahbf3fygbuf6deyri/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.2_next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwrigh_5nrp6tmwbahbf3fygbuf6deyri/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.2_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.45.3_react-dom@19._5ffssigtuqhpvo7bbpqgmhjoke/node_modules/next/dist/client/script.js");
