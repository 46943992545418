"use client";

import type { ReactNode } from "react";
import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import type { StoreConfig } from "./types";

export const StoreConfigContext = createContext<StoreConfig | null>(null);

type StoreConfigProviderProps = PropsWithChildren<{ config: StoreConfig }>;

export const StoreConfigProvider = ({
	children,
	config,
}: StoreConfigProviderProps): ReactNode => (
	<StoreConfigContext.Provider value={config}>
		{children}
	</StoreConfigContext.Provider>
);

export const useStoreConfig = (): StoreConfig => {
	const storeConfig = useContext(StoreConfigContext);

	if (!storeConfig) {
		throw new Error(
			"storeConfig is null: create a configuration of type StoreConfig and pass to StoreConfigProvider",
		);
	}

	return storeConfig;
};
