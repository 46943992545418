import {
	CLSThresholds,
	FCPThresholds,
	FIDThresholds,
	INPThresholds,
	LCPThresholds,
	TTFBThresholds,
} from "web-vitals";

export type NeedsImprovementRange = [number, number];
type Metric = "LCP" | "CLS" | "INP" | "FCP" | "TTFB" | "FID";

export const calcScore = (
	value: number,
	needsImprovementRange: NeedsImprovementRange,
): number => {
	const correctedVal = value - needsImprovementRange[0];
	const rangeLength = needsImprovementRange[1] - needsImprovementRange[0];
	const unit = Math.round(100 - (correctedVal / rangeLength) * 100);
	return Math.max(0, Math.min(100, unit));
};

// prettier-ignore
export const needsImprovementRanges: Record<Metric, NeedsImprovementRange> = {
	LCP: LCPThresholds, // https://web.dev/articles/lcp#what-is-a-good-lcp-score
	CLS: CLSThresholds, // https://web.dev/articles/cls#what-is-a-good-cls-score
	INP: INPThresholds, // https://web.dev/articles/inp#good-score
	FCP: FCPThresholds, // https://web.dev/articles/fcp#what-is-a-good-fcp-score
	TTFB: TTFBThresholds, // https://web.dev/articles/ttfb#good-ttfb-score
	FID: FIDThresholds, // https://web.dev/articles/fid#what_is_a_good_fid_score
};

// Weight of metric, determined by Iron/Out.
export const metricWeights: Record<Metric, number> = {
	TTFB: 0.1,
	FCP: 0.15,
	LCP: 0.25,
	CLS: 0.25,
	INP: 0.25,
	FID: 0,
};
