"use client";

import {
	HoneycombWebSDK,
	WebVitalsInstrumentation,
} from "@honeycombio/opentelemetry-web";

import type { Span } from "@opentelemetry/api";
import { useEffect } from "react";
import type { Metric } from "web-vitals/attribution";
import { calcScore, metricWeights, needsImprovementRanges } from "./calc-score";

type InstrumentationOptions = {
	apiKey: string;
	endpoint?: string;
	gqlEndpoint?: string;
};

const startClientSideInstrumentation = (options: InstrumentationOptions) => {
	// TODO: should probably only do this on production envs
	const applyCustomAttributes = (metric: Metric, span: Span) => {
		// Set score and weightedScore attribute
		const weight = metricWeights[metric.name];
		const range = needsImprovementRanges[metric.name];
		if (range && weight) {
			const name = metric.name.toLowerCase();
			const score = calcScore(metric.value, range);
			span.setAttribute(`${name}.score`, score);
			span.setAttribute(`${name}.weightedScore`, score * weight);
		}
	};

	const configDefaults = {
		ignoreNetworkEvents: true,
		propagateTraceHeaderCorsUrls: [] as (string | RegExp)[],
	};

	if (options.gqlEndpoint) {
		configDefaults.propagateTraceHeaderCorsUrls.push(
			new RegExp(options.gqlEndpoint + "/.*"),
		);
	}

	const sdk = new HoneycombWebSDK({
		apiKey: options.apiKey,
		endpoint: options.endpoint,
		serviceName: "site-client",
		debug: true,
		sampleRate: 1,
		instrumentations: [
			new WebVitalsInstrumentation({
				vitalsToTrack: ["LCP", "CLS", "INP", "FCP", "TTFB"],
				lcp: { applyCustomAttributes },
				cls: { applyCustomAttributes },
				inp: { applyCustomAttributes },
				fcp: { applyCustomAttributes },
				ttfb: { applyCustomAttributes },
			}),
			// new XMLHttpRequestInstrumentation(configDefaults),
			// new FetchInstrumentation(configDefaults),
		],
		skipOptionsValidation: true,
	});

	sdk.start();
	return sdk;
};

export const ClientSideInstrumentation = (
	props: InstrumentationOptions,
): null => {
	useEffect(() => {
		const sdk = startClientSideInstrumentation(props);
		return () => {
			void sdk?.shutdown();
		};
	}, []);
	return null;
};
